import { Component, Input } from '@angular/core';
import { Breadcrumb } from 'app/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input()
  public breadcrumbs: Breadcrumb[] | undefined;

  constructor() {}
}
