import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { link } from 'fs';
import { NavigationData } from 'app/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavComponent implements OnInit {
  @Input()
  data: NavigationData[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
