<ul
  class="list-none p-3 m-0 mb-3 bg-white flex align-items-center font-medium overflow-x-auto"
>
  <ng-container
    *ngFor="let breadcrumb of breadcrumbs; let first = first; let last = last"
  >
    <li *ngIf="!first" class="pr-3">
      <span class="text-400">/</span>
    </li>
    <li class="pr-3">
      <a
        *ngIf="!last && breadcrumb.url"
        [routerLink]="breadcrumb.url"
        class="cursor-pointer text-primary white-space-nowrap"
        >{{ breadcrumb.label }}</a
      >
      <label
        *ngIf="last || !breadcrumb.url"
        class="text-900 white-space-nowrap white-space-nowrap"
        >{{ breadcrumb.label }}</label
      >
    </li>
  </ng-container>
</ul>
