import { Injectable } from '@angular/core';
import { StorageKey } from '../models';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  get<Type>(key: StorageKey): Type {
    let value: string = this.getString(key) ?? '{}';
    return JSON.parse(value) as Type;
  }

  getObject(key: StorageKey): any {
    return JSON.parse(localStorage.getItem(key) || '{}');
  }

  getString(key: StorageKey): string | null {
    let value = localStorage.getItem(key);
    return value === null ? null : JSON.parse(value);
  }

  remove(key: StorageKey): void {
    localStorage.removeItem(key);
  }

  save(key: StorageKey, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  testLocalStorage(): void {
    const testValue = 'Lorem Ipsum';
    this.save(StorageKey.Test, testValue);
    const retrievedValue = this.getString(StorageKey.Test);
    this.remove(StorageKey.Test);

    if (retrievedValue !== testValue) {
      throw new Error(
        $localize`Test localStorage fail. Not returned the expected value.`
      );
    }
  }
}
