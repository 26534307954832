import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { KnobModule } from 'primeng/knob';
import { MessageService } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { SlideMenuModule } from 'primeng/slidemenu';
import { StyleClassModule } from 'primeng/styleclass';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { ToolbarModule } from 'primeng/toolbar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { TagModule } from 'primeng/tag';
import { TabViewModule } from 'primeng/tabview';
import { PanelModule } from 'primeng/panel';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { SliderModule } from 'primeng/slider';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToggleButtonModule } from 'primeng/togglebutton';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { TreeSelectModule } from 'primeng/treeselect';
import { HttpClientModule } from '@angular/common/http';
import {
  AlertDialogComponent,
  BlocksExamplesComponent,
  BreadcrumbComponent,
  DateRangeSelectorComponent,
  DateSelectorComponent,
  LoadingComponent,
  ModalDialogComponent,
  NavComponent,
  SessionExpiredComponent
} from './components';
import { SumPipe, YesNoPipe } from './pipes';
import { InputNumberModule } from 'primeng/inputnumber';

const primengModules = [
  AvatarModule,
  BadgeModule,
  ButtonModule,
  InputNumberModule,
  CalendarModule,
  ConfirmDialogModule,
  DialogModule,
  DividerModule,
  InputTextareaModule,
  KnobModule,
  MultiSelectModule,
  ProgressSpinnerModule,
  RippleModule,
  SlideMenuModule,
  StyleClassModule,
  TableModule,
  ToastModule,
  TreeSelectModule,
  InputSwitchModule,
  DropdownModule,
  CheckboxModule,
  CardModule,
  ToolbarModule,
  AutoCompleteModule,
  InputTextModule,
  PasswordModule,
  TagModule,
  TabViewModule,
  PanelModule,
  OrganizationChartModule,
  SliderModule,
  ProgressBarModule,
  ToggleButtonModule
];

const components = [
  AlertDialogComponent,
  BlocksExamplesComponent,
  BreadcrumbComponent,
  DateRangeSelectorComponent,
  DateSelectorComponent,
  LoadingComponent,
  ModalDialogComponent,
  NavComponent,
  SessionExpiredComponent
];

const pipes = [SumPipe, YesNoPipe];

@NgModule({
  declarations: [
    ...components,
    ...pipes,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    FontAwesomeModule,

    ...primengModules,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,

    ...primengModules,

    ...components,
    ...pipes,
  ],
  providers: [ConfirmationService, MessageService ],
})
export class SharedModule {}
