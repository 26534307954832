<p-dialog
  header="Header"
  [(visible)]="visible"
  [modal]="true"
  [closeOnEscape]="closable"
  [closable]="closable"
  [styleClass]="styleClass"
>
  <ng-template pTemplate="header">
    <ng-content select=".dialog-header"></ng-content>
  </ng-template>
  <ng-content select=".dialog-body"></ng-content>
  <ng-template pTemplate="footer">
    <ng-content select=".dialog-footer"></ng-content>
  </ng-template>
</p-dialog>
