import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.css']
})
export class ModalDialogComponent implements OnInit {
  visibleValue: boolean = false;

  @Input()
  closable: boolean = false;

  @Input()
  styleClass: string = '';

  @Input()
  get visible() {
    return this.visibleValue;
  }

  @Output() visibleChange = new EventEmitter();
  set visible(value) {
    this.visibleValue = value;
    this.visibleChange.emit(this.visibleValue);
  }

  constructor() { }

  ngOnInit(): void {
  }

}
