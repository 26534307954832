import { forwardRef, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PrimengNotificationService } from '.';
import { AlertMessage, ConfirmationMessage, ToastMessage } from '../models';

@Injectable({
  providedIn: 'root',
  useClass: forwardRef(() => PrimengNotificationService),
})
export abstract class NotificationService {
  protected alertMessageSubject: Subject<AlertMessage> = new Subject();
  protected confirmationMessageSubject: Subject<ConfirmationMessage> = new Subject();
  protected toastMessageSubject: Subject<ToastMessage> = new Subject();

  public readonly currentAlertMessage: Observable<AlertMessage> = this.alertMessageSubject.asObservable();
  public readonly currentConfirmationMessage: Observable<ConfirmationMessage> = this.confirmationMessageSubject.asObservable();
  public readonly currentToastMessage: Observable<ToastMessage> = this.toastMessageSubject.asObservable();

  constructor() {}

  abstract confirm(message: ConfirmationMessage): void;
  abstract openAlert(message: AlertMessage): void;
  abstract openToast(message: ToastMessage): void;
}
