import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthorizeService, IUser } from '../../api-authorization/authorize.service';
import { NotificationService, ToastMessage, MessageSeverity } from '../core';

@Component({
  selector: 'app-token-component',
  templateUrl: './token.component.html',
})
export class TokenComponent implements OnInit {
  token: string = '';
  user: Observable<IUser | null> | undefined;

  constructor(
    private authorizeService: AuthorizeService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.authorizeService.getAccessToken().subscribe((t) => {
      this.token = 'Bearer ' + t;
    });

    this.user = this.authorizeService.getUser();
  }

  copyToClipboard(): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.token;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.notificationService.openToast({
      severity: MessageSeverity.Success,
      summary: 'User profile',
      detail: 'JWT copied!',
    } as ToastMessage);
  }
}
