import { RoleClaimDto } from "./RoleClaimDto.model";

export class RoleDto implements IRoleDto {
  tenantId?: string;
  id?: string;
  name?: string | undefined;
  roleClaims?: RoleClaimDto[];

  constructor(data?: IRoleDto) {
      if (data) {
          for (var property in data) {
              if (data.hasOwnProperty(property))
                  (<any>this)[property] = (<any>data)[property];
          }
      }
  }

  init(_data?: any) {
      if (_data) {
          this.tenantId = _data["tenantId"];
          this.id = _data["id"];
          this.name = _data["name"];
          if (Array.isArray(_data["roleClaims"])) {
              this.roleClaims = [] as any;
              for (let item of _data["roleClaims"])
                  this.roleClaims!.push(RoleClaimDto.fromJS(item));
          }
      }
  }

  static fromJS(data: any): RoleDto {
      data = typeof data === 'object' ? data : {};
      let result = new RoleDto();
      result.init(data);
      return result;
  }

  toJSON(data?: any) {
      data = typeof data === 'object' ? data : {};
      data["tenantId"] = this.tenantId;
      data["id"] = this.id;
      data["name"] = this.name;
      if (Array.isArray(this.roleClaims)) {
          data["roleClaims"] = [];
          for (let item of this.roleClaims)
              data["roleClaims"].push(item.toJSON());
      }
      return data;
  }
}

export interface IRoleDto {
  tenantId?: string;
  id?: string;
  name?: string | undefined;
  roleClaims?: RoleClaimDto[];
}
