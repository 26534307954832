<body>
  <app-loading></app-loading>
  <div class="fixed w-full z-5">
    <app-nav-menu></app-nav-menu>
  </div>
  <main>
    <div class="main-layout" *ngIf="authService.instance.getAllAccounts().length > 0">
      <div class="main-layout__content-wrapper">
        <div class="main-layout__content">
          <router-outlet></router-outlet>
        </div>
      </div>
      <div class="main-layout__footer">
        <span class="text-xs" i18n>Copyright {{ now | date: 'yyyy' }} Lumar</span>
        <span class="text-xs font-medium ml-2">v{{version}}</span>
      </div>
    </div>
    <div class="main-layout" *ngIf="authService.instance.getAllAccounts().length <= 0">
      <div class="main-layout__content-wrapper">
        <div class="main-layout__content text-center">
      <h1>BIENVENIDO AL SERVICIO DE CLIENTES DE LUMAR</h1>
      <button (click)="loginPopup()" class="p-element  font-medium p-button p-component m-3">Iniciar sesión</button><br>
      <img src="/assets/LUMAR ESCRITORIO.jpg" style="max-width:100%;margin:10px"/>  
    </div></div>
    </div>
  </main>
</body>
<p-toast></p-toast>
<p-confirmDialog [closable]="confirmationMessage?.closable ?? false" class="w-9 lg:w-6"></p-confirmDialog>
<app-session-expired [(visible)]="displaySessionExpired"></app-session-expired>
<app-alert-dialog [(visible)]="displayAlertDialog" [message]="alertMessage"></app-alert-dialog>
