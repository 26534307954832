<p-dialog
  header="Session expired"
  [(visible)]="visible"
  [modal]="true"
  [closeOnEscape]="false"
  [closable]="false"
>
  <p class="m-0">
    Your session has expired. You will be redirect to the Login page.
  </p>
  <ng-template pTemplate="footer">
    <p-button
      (click)="login()"
      label="Sign in"
    ></p-button>
  </ng-template>
</p-dialog>
