import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationPaths } from 'api-authorization/api-authorization.constants';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionExpiredComponent implements OnInit {
  visibleValue: boolean = false;

  @Input()
  get visible() {
    return this.visibleValue;
  }

  @Output() visibleChange = new EventEmitter();
  set visible(value) {
    this.visibleValue = value;
    this.visibleChange.emit(this.visibleValue);
  }

  constructor(private router: Router) {}

  async login() {
    alert('expired');
    this.visible = false;
    await this.router.navigateByUrl(ApplicationPaths.Login, {
      replaceUrl: true,
    });
  }

  ngOnInit(): void {}
}
