import { Injectable,Inject,OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest, EventMessage, EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})

export class AuthenticationService  {
  private readonly _destroying$ = new Subject<void>();
public isAuthenticated:boolean;
public userName:string | undefined;
   constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpClient
  ) { 

    this.userName ='';
    this.isAuthenticated = false;
      this.LoadAsync();


    // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    
  }
  private LoadAsync = async () => {
    
      
    //await this.authService.instance.enableAccountStorageEvents(); 
     this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.updateLogin();
        }
      });
    
       this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.updateLogin();
        this.checkAndSetActiveAccount();
      })
      
 };


  checkAndSetActiveAccount(){
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }
  loginRedirect() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }

  }

  async loginPopup() {
    if ( this.msalGuardConfig.authRequest){
     this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
          
        });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
      });
    }

  }

  async logout(popup?: boolean) {
    if (popup) {
     await this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
     await this.authService.logoutRedirect();
    }

  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
 async checkUserRole(role: string){
    const account = await this.authService.instance.getActiveAccount();
    if (!account) return false;

    const token = account.idToken;
    if (!token) return false;
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);

    return decodedToken.roles && decodedToken.roles.includes(role);
  }


async updateLogin(){
  this.isAuthenticated = this.authService.instance.getAllAccounts().length > 0;
  this.userName = (await this.getUserPrincipalName()).toString();
}
async getUserPrincipalName(){
  console.log()
  const account = await this.authService.instance.getActiveAccount();
  if (!account) return false;

  return account.username;
  
}


}
