export class RoleClaimDto implements IRoleClaimDto {
  claimType?: string | undefined;
  claimValue?: string | undefined;

  constructor(data?: IRoleClaimDto) {
      if (data) {
          for (var property in data) {
              if (data.hasOwnProperty(property))
                  (<any>this)[property] = (<any>data)[property];
          }
      }
  }

  init(_data?: any) {
      if (_data) {
          this.claimType = _data["claimType"];
          this.claimValue = _data["claimValue"];
      }
  }

  static fromJS(data: any): RoleClaimDto {
      data = typeof data === 'object' ? data : {};
      let result = new RoleClaimDto();
      result.init(data);
      return result;
  }

  toJSON(data?: any) {
      data = typeof data === 'object' ? data : {};
      data["claimType"] = this.claimType;
      data["claimValue"] = this.claimValue;
      return data;
  }
}

export interface IRoleClaimDto {
  claimType?: string | undefined;
  claimValue?: string | undefined;
}
