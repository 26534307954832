<div class="grid">
  <div class="col-12 lg:col-2">
    <div class="shadow-2 surface-card border-round p-4 h-full">
      <div class="text-900 font-medium mb-2 text-xl">Title Placeholder</div>
      <p class="mt-0 mb-4 p-0 line-height-3">
        Nunc mi ipsum faucibus vitae aliquet nec. Lacus sed viverra tellus in
        hac habitasse platea dictumst.
      </p>
    </div>
  </div>
  <div class="col-12 lg:col-6">
    <div class="shadow-2 surface-card border-round p-4 h-full">
      <div class="text-900 font-medium mb-2 text-xl">Latest News</div>
      <ul class="list-none p-0 m-0">
        <li class="pb-3 border-bottom-1 surface-border">
          <div class="font-medium text-900 mb-2">Aenean euismod elementum</div>
          <div class="line-height-3 text-600" style="max-width: 30rem">
            Vitae turpis massa sed elementum tempus egestas sed sed risus. In
            metus vulputate eu scelerisque felis imperdiet proin.
          </div>
        </li>
        <li class="py-3 border-bottom-1 surface-border">
          <div class="font-medium text-900 mb-2">
            In iaculis nunc sed augue lacus
          </div>
          <div class="line-height-3 text-600" style="max-width: 30rem">
            Viverra vitae congue. Nisi scelerisque eu ultrices vitae auctor eu
            augue ut lectus. Elementum eu facilisis sed odio morbi.
          </div>
        </li>
        <li class="py-3 border-bottom-1 surface-border">
          <div class="font-medium text-900 mb-2">
            Proin sagittis nisl rhoncus
          </div>
          <div class="line-height-3 text-600" style="max-width: 30rem">
            In pellentesque massa placerat duis ultricies lacus. Ac feugiat sed
            lectus vestibulum mattis ullamcorper.
          </div>
        </li>
      </ul>
      <div class="flex justify-content-between pt-3">
        <button
          (click)="testInfoAlert()"
          pButton
          type="button"
          label="Test info alert"
          class="w-6 mr-2"
        ></button>
        <button
          (click)="testWarningAlert()"
          pButton
          type="button"
          label="Test warning alert"
          class="p-button-warning w-6 mr-2"
        ></button>
        <button
          (click)="testConfirm()"
          pButton
          type="button"
          label="Test confirm"
          class="p-button-danger w-6 mr-2"
        ></button>
        <button
          (click)="toggleModalDialog()"
          pButton
          type="button"
          label="Test modal dialog"
          class="p-button-success w-6 mr-2"
        ></button>
        <button
          pButton
          type="button"
          label="New Entry"
          class="w-6"
          disabled="true"
        ></button>
      </div>
    </div>
  </div>
  <div class="col-12 lg:col-2">
    <div class="shadow-2 surface-card border-round p-4 h-full">
      <div class="flex align-items-start mb-5">
        <img
          src="https://blocks.primeng.org/assets/images/blocks/avatars/circle-big/avatar-f-2.png"
          width="56"
          height="56"
        />
        <div class="ml-3">
          <span class="block text-900 mb-1 text-xl font-medium"
            >Kathryn Murphy</span
          >
          <p class="text-600 mt-0 mb-0">Sales Manager</p>
        </div>
      </div>
      <ul class="list-none p-0 m-0">
        <li class="mb-5">
          <div class="flex justify-content-between align-items-center">
            <span
              class="text-900 inline-flex justify-content-between align-items-center"
              ><i class="pi pi-twitter mr-2"></i
              ><span class="font-medium text-900">Twitter</span></span
            ><span class="text-cyan-500 font-medium">64.47%</span>
          </div>
          <div
            class="surface-300 w-full mt-2"
            style="height: 7px; border-radius: 4px"
          >
            <div
              class="bg-cyan-500 h-full"
              style="width: 64%; border-radius: 4px"
            ></div>
          </div>
        </li>
        <li class="mb-5">
          <div class="flex justify-content-between align-items-center">
            <span
              class="text-900 inline-flex justify-content-between align-items-center"
              ><i class="pi pi-facebook mr-2"></i
              ><span class="font-medium text-900">Facebook</span></span
            ><span class="text-indigo-500 font-medium">75.67%</span>
          </div>
          <div
            class="surface-300 w-full mt-2"
            style="height: 7px; border-radius: 4px"
          >
            <div
              class="bg-indigo-500 h-full"
              style="width: 75%; border-radius: 4px"
            ></div>
          </div>
        </li>
        <li>
          <div class="flex justify-content-between align-items-center">
            <span
              class="text-900 inline-flex justify-content-between align-items-center"
              ><i class="pi pi-google mr-2"></i
              ><span class="font-medium text-900">Google</span></span
            ><span class="text-orange-500 font-medium">45.00%</span>
          </div>
          <div
            class="surface-300 w-full mt-2"
            style="height: 7px; border-radius: 4px"
          >
            <div
              class="bg-orange-500 h-full"
              style="width: 45%; border-radius: 4px"
            ></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-12 lg:col-2">
    <div class="shadow-2 surface-card border-round p-4 h-full">
      <div class="text-900 font-medium mb-2 text-xl">Vertical form</div>
      <div class="field">
        <label for="firstname1">Firstname</label>
        <input
          id="firstname1"
          type="text"
          class="text-base text-color p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full"
        />
      </div>
      <div class="field">
        <label for="lastname1">Lastname</label>
        <input
          id="lastname1"
          type="text"
          class="text-base text-color p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full"
        />
      </div>
      <div class="flex flex-row pt-3">
        <button pButton type="button" label="Save" class="mr-2"></button>
        <button
          pButton
          type="button"
          label="Cancel"
          class="p-button-text"
        ></button>
      </div>
    </div>
  </div>
</div>

<div class="grid mt-4">
  <div class="col-12 lg:col-2">
    <ul
      class="h-full list-none m-0 p-0 flex flex-row lg:flex-column justify-content-evenly md:justify-content-between lg:justify-content-start bg-white"
    >
      <li>
        <a
          pripple=""
          class="p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-900 bg-primary-50 text-primary transition-duration-150 transition-colors border-left-3 border-transparent border-primary mr-2"
          ><i class="pi pi-user md:mr-2"></i
          ><span class="font-medium hidden md:block">Profile</span
          ><span class="p-ink"></span
        ></a>
      </li>
      <li>
        <a
          pripple=""
          class="p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-900 hover:text-primary transition-duration-150 transition-colors"
          ><i class="pi pi-cog md:mr-2"></i
          ><span class="font-medium hidden md:block">Account</span
          ><span class="p-ink"></span
        ></a>
      </li>
      <li>
        <a
          pripple=""
          class="p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-900 hover:text-primary transition-duration-150 transition-colors"
          ><i class="pi pi-palette md:mr-2"></i
          ><span class="font-medium hidden md:block">Appearance</span
          ><span class="p-ink"></span
        ></a>
      </li>
      <li>
        <a
          pripple=""
          class="p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-900 hover:text-primary transition-duration-150 transition-colors"
          ><i class="pi pi-sun md:mr-2"></i
          ><span class="font-medium hidden md:block">Accessibility</span
          ><span class="p-ink"></span
        ></a>
      </li>
      <li>
        <a
          pripple=""
          class="p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-900 hover:text-primary transition-duration-150 transition-colors"
          ><i class="pi pi-bell md:mr-2"></i
          ><span class="font-medium hidden md:block">Notifications</span
          ><span class="p-ink"></span
        ></a>
      </li>
    </ul>
  </div>
  <div class="col-12 lg:col-8">
    <div class="h-full shadow-2 surface-card border-round p-4 flex-auto">
      <div class="text-900 font-medium mb-2 text-xl">Profile</div>
      <p-divider></p-divider>
      <div class="flex gap-5 flex-column-reverse md:flex-row">
        <div class="flex-auto p-fluid">
          <div class="field">
            <label for="email">Name</label
            ><input
              id="email"
              type="text"
              pinputtext=""
              class="text-base text-color p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full"
            />
          </div>
          <div class="field">
            <label for="bio">Bio</label
            ><textarea
              id="bio"
              type="text"
              pinputtextarea=""
              rows="5"
              class="text-base text-color p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full"
              style="height: 129px; overflow: hidden"
            ></textarea>
          </div>
          <div class="field">
            <label for="state">Company</label
            ><input
              id="state"
              type="text"
              pinputtext=""
              class="text-base text-color p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full"
            />
          </div>
          <div>
            <button
              pbutton=""
              pripple=""
              label="Update Profile"
              class="p-element p-ripple w-auto p-button p-component"
            >
              <span class="p-button-label">Update Profile</span
              ><span class="p-ink"></span>
            </button>
          </div>
        </div>
        <div class="flex flex-column align-items-center flex-or">
          <span class="font-medium text-900 mb-2">Profile Picture</span
          ><img
            src="https://blocks.primeng.org/assets/images/blocks/avatars/circle-big/avatar-f-2.png"
            class="h-10rem w-10rem"
          /><button
            pbutton=""
            type="button"
            icon="pi pi-pencil"
            class="p-element p-button-rounded -mt-4 p-button p-component p-button-icon-only"
          >
            <span class="p-button-icon pi pi-pencil" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 lg:col-2">
    <div class="shadow-2 surface-card border-round p-4 h-full">
      <div class="flex flex-column align-items-center gap-6">
        <p class="text-900 text-center font-medium mt-0 mb-0">Deviation of IOT imputarion</p>
        <p-knob [ngModel]="44" [readonly]="true" valueTemplate="{value}%"></p-knob>
        <div class="flex flex-column align-items-center">
          <button
            pButton
            type="button"
            label="Send Report"
            class="w-full"
          ></button>
          <p class="text-center text-xs text-600">Pending validation, if everithing os ok you will receive your invoice shortly.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal-dialog [(visible)]="modalDialogVisible" [closable]="true" styleClass="w-9 lg:w-6">
  <div class="dialog-header text-900 font-bold text-xl">My modal dialog header</div>
    <div class="grid formgrid p-fluid dialog-body">
      <div class="formgrid grid">
        <div class="field col-12 md:col-6">
            <label for="firstname6">Firstname</label>
            <input id="firstname6" type="text" class="text-base text-color p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full">
        </div>
        <div class="field col-12 md:col-6">
            <label for="lastname6">Lastname</label>
            <input id="lastname6" type="text" class="text-base text-color p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full">
        </div>
        <div class="field col-12">
            <label for="address">Address</label>
            <textarea id="address" type="text" rows="4" class="text-base text-color p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full"></textarea>
        </div>
        <div class="field col-12 md:col-6">
            <label for="city">City</label>
            <input id="city" type="text" class="text-base text-color p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full">
        </div>
        <div class="field col-12 md:col-3">
            <label for="state">State</label>
            <select id="state" class="text-base text-color p-2 border-1 border-solid outline-none focus:border-primary w-full" style="appearance: auto">
                <option>Arizona</option>
                <option>California</option>
                <option>Florida</option>
                <option>Ohio</option>
                <option>Washington</option>
            </select>
        </div>
        <div class="field col-12 md:col-3">
            <label for="zip">Zip</label>
            <input id="zip" type="text" class="text-base text-color p-2 border-1 border-solid appearance-none outline-none focus:border-primary w-full">
        </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button
      (click)="toggleModalDialog()"
      pButton
      type="button"
      label="Cancel"
      class="p-button-text"
      ></button>
    <button (click)="toggleModalDialog()" pButton type="button" label="Save" class="mr-2"></button>
  </div>
</app-modal-dialog>
