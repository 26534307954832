import { PrimengLocale } from 'app/core/models/primeng-locale.model';

export const primengLocale: PrimengLocale = {
  locale: 'es',
  translation: {
    startsWith: 'Empieza por',
    contains: 'Contiene',
    notContains: 'No contiene',
    endsWith: 'Termina con',
    equals: 'Igual a',
    notEquals: 'No es igual',
    noFilter: 'Sin filtro',
    lt: 'Menor que',
    lte: 'Menor o igual que',
    gt: 'Mayor que',
    gte: 'Mayor o igual que',
    is: 'Es',
    isNot: 'No es',
    before: 'Antes',
    after: 'Después',
    dateIs: 'La fecha es',
    dateIsNot: 'La fecha no es',
    dateBefore: 'La fecha es antes',
    dateAfter: 'La fecha es después',
    clear: 'Limpiar',
    apply: 'Aplicar',
    matchAll: 'Coincidir todos',
    matchAny: 'Coincidir con cualquiera',
    addRule: 'Añadir regla',
    removeRule: 'Eliminar regla',
    accept: 'Sí',
    reject: 'No',
    choose: 'Elegir',
    upload: 'Subir',
    cancel: 'Cancelar',
    dayNames: [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ],
    dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
    dayNamesMin: ['DO', 'LU', 'MA', 'MI', 'JU', 'VI', 'SA'],
    monthNames: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
    monthNamesShort: [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic',
    ],
    dateFormat: 'dd/mm/yy',
    firstDayOfWeek: 1,
    today: 'Hoy',
    weekHeader: 'Sm',
    weak: 'Débil',
    medium: 'Medio',
    strong: 'Fuerte',
    passwordPrompt: 'Introduzca una contraseña',
    emptyMessage: 'No se han encontrado resultados',
    emptyFilterMessage: 'No se han encontrado resultados',
  },
};
