export class HolidaysStatus {
  private static statuses: HolidaysStatus[] = [];

  static toList(): HolidaysStatus[] {
    return this.statuses;
  }

  static readonly Draft = new HolidaysStatus(1, 'D', 'DRAFT');
  static readonly InProgress = new HolidaysStatus(2, 'IP', 'IN PROGRESS');
  static readonly Finished = new HolidaysStatus(3, 'F', 'FINISHED');
  static readonly Complete = new HolidaysStatus(4, 'C', 'COMPLETE');
  static readonly Unstarted = new HolidaysStatus(5, 'U', 'UNSTARTED');
  static readonly WaitingForApproval = new HolidaysStatus(6, 'WA', 'WAITING FOR APPROVAL');
  static readonly Rejected = new HolidaysStatus(7, 'RJ', 'REJECTED');
  static readonly Approved = new HolidaysStatus(8, 'AP', 'APPROVED');
  static readonly UnderEvaluation = new HolidaysStatus(9, 'UE', 'UNDER EVALUATION');
  static readonly UnderDefinition = new HolidaysStatus(10, 'UD', 'UNDER DEFINITION');

  constructor(
    public readonly id: number,
    public readonly code: string,
    public readonly name: string
  ) {
    HolidaysStatus.statuses.push(this);
  }
}
