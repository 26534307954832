import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AlertMessage } from 'app/core';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertDialogComponent {
  visibleValue: boolean = false;

  @Input()
  get visible() {
    return this.visibleValue;
  }

  @Output() visibleChange = new EventEmitter();
  set visible(value) {
    this.visibleValue = value;
    this.visibleChange.emit(this.visibleValue);
  }

  @Input()
  message: AlertMessage | undefined;

  constructor() {}

  asArray(content: string | string[] | undefined): string[] {
    return content as string[];
  }

  getHeader(): string {
    return this.message?.header ?? '';
  }

  getStyleClass(): string {
    return `w-9 lg:w-6 p-message p-message-${this.message?.severity}`;
  }

  isArray(content: string | string[] | undefined): boolean {
    return Array.isArray(content);
  }
}
