<section class="surface-section px-3 py-4 lg:px-6 lg:py-8">
  <div class="text-3xl font-medium text-900 mb-3">JWT</div>
  <h3>Hello {{ (user | async)?.given_name }}!</h3>
  <p>{{ user | async | json }}</p>
  <div
    style="height: 150px; word-break: break-all"
    class="border-2 border-dashed surface-border mb-3 h-full"
  >
    {{ token }}
  </div>
  <button pButton (click)="copyToClipboard()">Copy</button>
</section>
