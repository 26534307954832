import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { ToastMessage, MessageSeverity } from '../models';

import { NotificationService } from '../services';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private notificationService: NotificationService,
    private zone: NgZone
  ) {}

  handleError(error: Error) {
    this.zone.run(() => {
      this.notificationService.openToast({
        severity: MessageSeverity.Error,
        sticky: true,
        closable: true,
        summary: $localize`An error has occurred`,
        detail: error.message || $localize`Unhandled error.`,
      } as ToastMessage);
    });
  }
}
