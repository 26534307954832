import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

import { primengLocale as enUsPrimengLocale } from 'locale/primeng/en-US';
import { primengLocale as esPrimengLocale } from 'locale/primeng/es';
import { primengLocale as ptPrimengLocale } from 'locale/primeng/pt';
import { AuthorizeService } from 'api-authorization/authorize.service';
import { PrimengLocale } from './core/models/primeng-locale.model';
import {
  AlertMessage,
  ConfirmationMessage,
  NotificationService,
  StorageService,
  VersionUpdateService,
} from './core';
import { environment } from 'environments/environment';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs';
import { AuthenticationService } from './authentication/authentication.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  version: string = environment.version;
  now = Date.now();
  title = 'app';
  displaySessionExpired: boolean = false;
  displayAlertDialog: boolean = false;
  alertMessage: AlertMessage | undefined;
  confirmationMessage: ConfirmationMessage | undefined;
  loginDisplay = false;
  claims:any;
  constructor(
    private storageService: StorageService,
    private primengConfig: PrimeNGConfig,
    private authorizeService: AuthorizeService,
    private notificationService: NotificationService,
    private versionUpdateService: VersionUpdateService,
    public authService: MsalService, 
    private msalBroadcastService: MsalBroadcastService
  ) {}

  async ngOnInit() {
    this.authService.initialize().subscribe();
    this.authService.handleRedirectObservable().subscribe({
      next: (result)=>{
        console.log("RESULT: "+result);
      },
      error: (error) =>{
        console.log("ERROR: "+error);
      }
    })
    this.storageService.testLocalStorage();
    this.initPrimeng();
    
    this.authorizeService.sessionExpired.subscribe((expired) => {
      if (expired === true) {
        this.displaySessionExpired = true;
      }
    });

    this.notificationService.currentAlertMessage.subscribe((message) => {
      if (message) {
        this.alertMessage = message;
        this.displayAlertDialog = true;
      }
    });

    this.notificationService.currentConfirmationMessage.subscribe((message) => {
      if (message) {
        this.confirmationMessage = message;
      }
    });
    this.leerClaims();
  
  }
  async loginPopup() {
    await this.authService.loginPopup();
   }
   setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
  private initPrimeng() {
    this.primengConfig.ripple = true;

    const locale = this.getPrimengLocale();
    if (locale) {
      this.primengConfig.setTranslation(locale.translation);
    }
  }

  private getPrimengLocale(): PrimengLocale | undefined {
    const locales = [enUsPrimengLocale, esPrimengLocale, ptPrimengLocale];
    return locales.find((l) => l.locale === $localize.locale);
  }

  leerClaims() {
    // Obtiene el objeto de cuenta del usuario autenticado
    const cuenta = this.authService.instance.getAllAccounts()[0]; // Suponiendo un solo usuario

    if (cuenta) {
      // Accede a los claims desde el ID Token
      this.claims = cuenta.idTokenClaims;

      // Ahora puedes usar los claims como necesites
      console.log(this.claims);
    }
  }
}
