import { UserClaimDto } from "./UserClaimDto.model";
import { UserRoleDto } from "./UserRoleDto.model";

export class UserDto implements IUserDto {
  tenantId?: string;
  id?: string;
  email?: string | undefined;
  userName?: string | undefined;
  givenName?: string;
  familyName?: string;
  isEnabled?: boolean;
  claims?: UserClaimDto[];
  userRoles?: UserRoleDto[];

  constructor(data?: IUserDto) {
      if (data) {
          for (var property in data) {
              if (data.hasOwnProperty(property))
                  (<any>this)[property] = (<any>data)[property];
          }
      }
  }

  init(_data?: any) {
      if (_data) {
          this.tenantId = _data["tenantId"];
          this.id = _data["id"];
          this.email = _data["email"];
          this.userName = _data["userName"];
          this.givenName = _data["givenName"];
          this.familyName = _data["familyName"];
          this.isEnabled = _data["isEnabled"];
          if (Array.isArray(_data["claims"])) {
              this.claims = [] as any;
              for (let item of _data["claims"])
                  this.claims!.push(UserClaimDto.fromJS(item));
          }
          if (Array.isArray(_data["userRoles"])) {
              this.userRoles = [] as any;
              for (let item of _data["userRoles"])
                  this.userRoles!.push(UserRoleDto.fromJS(item));
          }
      }
  }

  static fromJS(data: any): UserDto {
      data = typeof data === 'object' ? data : {};
      let result = new UserDto();
      result.init(data);
      return result;
  }

  toJSON(data?: any) {
      data = typeof data === 'object' ? data : {};
      data["tenantId"] = this.tenantId;
      data["id"] = this.id;
      data["email"] = this.email;
      data["userName"] = this.userName;
      data["givenName"] = this.givenName;
      data["familyName"] = this.familyName;
      data["isEnabled"] = this.isEnabled;
      if (Array.isArray(this.claims)) {
          data["claims"] = [];
          for (let item of this.claims)
              data["claims"].push(item.toJSON());
      }
      if (Array.isArray(this.userRoles)) {
          data["userRoles"] = [];
          for (let item of this.userRoles)
              data["userRoles"].push(item.toJSON());
      }
      return data;
  }
}

export interface IUserDto {
  tenantId?: string;
  id?: string;
  email?: string | undefined;
  userName?: string | undefined;
  givenName?: string;
  familyName?: string;
  isEnabled?: boolean;
  claims?: UserClaimDto[];
  userRoles?: UserRoleDto[];
}
