<ul
  class="h-full list-none m-0 p-0 flex flex-row lg:flex-column justify-content-evenly md:justify-content-between lg:justify-content-start bg-white"
>
  <li *ngFor="let nav of data">
    <a
      [routerLink]="[nav.link]"
      [routerLinkActive]="['bg-primary-50', 'text-primary', 'border-left-3', 'border-primary', 'mr-2']"
      pripple=""
      class="p-ripple p-element flex align-items-center cursor-pointer p-3 text-900 hover:text-primary transition-duration-150 transition-colors outline-none"
      ><i *ngIf="nav.icon" class="font-medium text-xl md:mr-2" [class]="nav.icon"></i
      ><span class="font-medium hidden md:block white-space-nowrap overflow-hidden text-overflow-ellipsis">{{nav.text}}</span
      ><span class="p-ink"></span
    ></a>
  </li>
</ul>
