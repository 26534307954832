import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, take } from 'rxjs';
//import { UserDto, ResultString, UsersClient } from 'src/app/web-api-client';
import { UserDto } from '../models/UserDto.model';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  //constructor(private client: UsersClient) {}

  async current(): Promise<UserDto> {
    var user = new UserDto();
    user.id = "1";
    user.givenName = "John";
    user.familyName = "Doe";
    user.email = "jdoe@t.es";
    return user;
    //return await firstValueFrom(this.client.current());
  }
}
