import {
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { LoadingService, NotificationService } from '../services';
import { ToastMessage, MessageSeverity } from '../models';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  setLoadingBlackList: string[] = ['GetUserPendingNotifications'];

  constructor(
    private notificationService: NotificationService,
    private loadingService: LoadingService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.setLoading(true, request);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const customErrorTitle: string = this.getCustomErrorTitle(error);
        const customErrorMessages: string[] =
          this.getCustomErrorMessages(error);
        this.notificationService.openToast({
          severity: MessageSeverity.Error,
          sticky: true,
          closable: true,
          summary: customErrorTitle,
          detail: customErrorMessages.length
            ? customErrorMessages
            : error.message ?? JSON.stringify(error),
        } as ToastMessage);
        return throwError(error);
      }),
      finalize(() => {
        this.setLoading(false, request);
      })
    ) as Observable<HttpEvent<any>>;
  }

  private setLoading(enable: boolean, request: HttpRequest<any>): void {
    if (
      this.setLoadingBlackList.some((w) =>
        request.url.toLowerCase().includes(w.toLowerCase())
      )
    ) {
      return;
    }

    this.loadingService.setLoading(enable);
  }

  private getCustomErrorMessages(error: HttpErrorResponse): string[] {
    let errors: string[] = [];

    if (error?.error?.error?.message) {
      errors.push(error?.error?.error?.message);
    }

    if (error?.error?.errors) {
      Object.keys(error.error.errors).forEach((key) => {
        error.error.errors[key].forEach((error: string) => {
          errors.push(`${error} [${key}]`);
        });
      });
    }

    if (error?.error?.detail) errors.push(error?.error.detail);

    return errors;
  }

  private getCustomErrorTitle(error: HttpErrorResponse): string {
    return error?.error?.title;
  }
}
