<header
  class="surface-overlay px-3 lg:px-6 border-bottom-2 border-primary flex justify-content-between relative lg:static"
>
  <a [routerLink]="['/']" class="mr-0 lg:mr-6 align-self-center outline-none">
    <img src="assets/lumarcolor.png" alt="Logo Lumar" style="height: 80px;"/>
  </a>
  <a
    (click)="toggle()"
    class="cursor-pointer block lg:hidden align-self-center text-700 outline-none"
    ><i class="pi pi-bars text-4xl"></i
  ></a>

  <!-- <app-nav [data]="navigationData"></app-nav> -->
  <nav
    [ngClass]="{ hidden: !isExpanded }"
    class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-1 lg:shadow-none"
  >
    <ul class="list-none p-0 m-0 flex select-none flex-column lg:flex-row">
      <li *ngFor="let navigation of navigationData">
        <a
          [routerLink]="[navigation.link]"
          [routerLinkActiveOptions]="{ exact: navigation.exactActiveRouterLink ?? true }"
          [routerLinkActive]="['text-primary', 'bg-primary-50']"
          class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-lg text-900 hover:text-primary focus:text-primary font-medium border-round cursor-pointer transition-colors transition-duration-150 outline-none"
          ><span
            [routerLinkActiveOptions]="{ exact: navigation.exactActiveRouterLink ?? true }"
            [routerLinkActive]="['border-primary']"
            class="border-bottom-3 border-transparent"
            >{{navigation.text}}</span
          ></a
        >
      </li>
    </ul>
    <ul
      class="list-none p-0 m-0 flex select-none flex-column lg:flex-row surface-border lg:border-top-none"
      [ngClass]="{
        'border-top-0': !(authService.isAuthenticated),
        'border-top-1': (authService.isAuthenticated)
      }"
    >
      
      <ng-container *ngIf="this.authService.isAuthenticated">
        <li class="hidden lg:flex">
          <a
            [routerLink]="[]"
            (click)="menu.toggle($event)"
            class="flex px-6 p-3 lg:pl-3 lg:pr-0 lg:py-2 align-items-center text-lg text-900 hover:text-primary focus:text-primary font-medium border-round cursor-pointer transition-colors transition-duration-150 outline-none"
            ><p-avatar
              [label]="(authService.userName) ?? 'U' | uppercase | slice:0:1"
              [style]="{
                backgroundColor: 'var(--primary-color)',
                color: 'var(--primary-color-text)'
              }"
              shape="circle"
            ></p-avatar
          ></a>
          <p-slideMenu
            #menu
            [model]="items"
            [popup]="true"
            [viewportHeight]="140"
          ></p-slideMenu>
        </li>
        <!-- <li class="lg:hidden">
          <a
            [routerLink]="['/token']"
            [routerLinkActive]="['text-primary']"
            class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-lg text-900 hover:text-primary focus:text-primary font-medium border-round cursor-pointer transition-colors transition-duration-150 outline-none"
            ><i class="pi pi-fw pi-cog lg:text-2xl mr-2 lg:mr-0"></i
            ><span class="block font-medium" i18n>Preferences</span></a
          >
        </li> -->
        <li class="lg:hidden">
          <a
            [routerLink]="['/authentication/profile']"
            [routerLinkActive]="['text-primary']"
            class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-lg text-900 hover:text-primary focus:text-primary font-medium border-round cursor-pointer transition-colors transition-duration-150 outline-none"
            ><i class="pi pi-fw pi-user lg:text-2xl mr-2 lg:mr-0"></i
            ><span class="block font-medium" i18n>User profile</span></a
          >
        </li>
        <li class="lg:hidden">
          <button
            (click)="logout(false)"
            
            class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-lg text-900 hover:text-primary focus:text-primary font-medium border-round cursor-pointer transition-colors transition-duration-150 outline-none"
            ><i class="pi pi-fw pi-power-off lg:text-2xl mr-2 lg:mr-0"></i
            ><span class="block font-medium" i18n>Quit</span></button
          >
        </li>
      </ng-container>
      <ng-container *ngIf="!this.authService.isAuthenticated">
        <li>
          <button
          (click)="loginPopup()"
            pButton
            type="button"
            i18n-label
            label="Sign in"
            class="flex ml-6 my-3 lg:ml-0 lg:my-0 font-medium"
          ></button>
        </li>
      </ng-container>
      
    </ul>
  </nav>
</header>
