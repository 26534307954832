import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthorizeGuard } from '../api-authorization/authorize.guard';
import { HomeComponent } from './home/home.component';
import { TokenComponent } from './token/token.component';
import { BlocksExamplesComponent } from './shared';
import { ProfileComponent } from './authorization/profile/profile.component';
import { MsalGuard } from '@azure/msal-angular';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
     path: 'home',
     component: HomeComponent,
     canActivate: [MsalGuard],
  },
  {
    path: 'authorization',
    loadChildren: () =>
      import('./authorization/authorization.module').then((m) => m.AuthorizationModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),

  },
  {
    path: 'customermanagement',
    loadChildren: () =>
      import('./customermanagement/customermanagement.module').then((m) => m.CustomerManagementModule),
      canActivate: [MsalGuard]
  },

  {
    path: 'token',
    component: TokenComponent,
    canActivate: [MsalGuard],
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
