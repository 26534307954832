import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { ErrorHandlerModule } from './error-handler';

@NgModule({
  declarations: [],
  imports: [CommonModule, ErrorHandlerModule],
  providers: [DatePipe],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error(
        $localize`CoreModule is already loaded. Import it in the AppModule only.`
      );
    }
  }
}
