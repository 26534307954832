import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Calendar } from 'primeng/calendar';

export type DateRangeSelectorType = 'date' | 'month' | 'year';

@Component({
  selector: 'app-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrls: ['./date-range-selector.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeSelectorComponent implements OnInit {
  @ViewChild('dateRangeSelector') dateRangeSelector: Calendar | undefined;

  datesValues: Date[] = [new Date(), new Date()];
  viewValue: DateRangeSelectorType = 'date';
  dateFormat: string = '';

  @Input()
  get view(): DateRangeSelectorType {
    return this.viewValue;
  }
  set view(value) {
    this.viewValue = value;

    if (value === 'month') {
      this.dateFormat = 'mm/yy';
    } else if (value === 'year') {
      this.dateFormat = 'yy';
    }
  }

  @Input()
  minDate: Date = new Date();

  @Input()
  maxDate: Date = new Date();

  @Input()
  get dates(): Date[] {
    return this.datesValues;
  }
  set dates(value) {
    this.datesValues = value;
  }

  @Output() datesChange = new EventEmitter<Date[]>();

  constructor() {}

  onSelect(dates: Date) {
    this.datesChange.emit(this.datesValues);

    if (this.datesValues[0] !== null && this.datesValues[1] !== null) {
      this.dateRangeSelector?.hideOverlay();
    }
  }

  ngOnInit(): void {}
}
