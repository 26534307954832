import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import {
  EMPTY,
  Observable,
  Subscription,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  firstValueFrom,
} from 'rxjs';
import { AuthorizeService } from 'api-authorization/authorize.service';
import { NavigationData } from '../core/models';
import { AutoUnsubscribe } from '../shared/decorators';
import { AdminPermissions } from 'api-authorization';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationService } from '../authentication/authentication.service';
import { MenuItem } from 'primeng/api';
@AutoUnsubscribe()
@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  providers:[AuthenticationService],
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent implements OnInit {
  //isAuthenticated?: Observable<boolean>;
  isAuthenticated: boolean | undefined;
  isExpanded = false;
  navigationData: NavigationData[] = [];
  pendingNotifications: number = 0;
  userName: string | undefined;
  items: MenuItem[] = [];
  private routerSubscription: Subscription | undefined;
  private pendingNotificationsSubscription: Subscription | undefined;

  constructor(
    private authorizeService: AuthorizeService,
    private router: Router,
    public authService: AuthenticationService,
  ) {

    this.items = [
      // {
      //   label: $localize`Preferences`,
      //   icon: 'pi pi-fw pi-cog',
      //   routerLink: '/token',
      // },
      {
        label: $localize`User profile`,
        icon: 'pi pi-fw pi-user',
        routerLink: '/authorization/profile',
      },
      {
        separator: true,
      },
      {
        label: $localize`Sign out`,
        icon: 'pi pi-fw pi-power-off',
        command: () => this.logout(false),
        state: { local: true },
      },
    ];
    
  }
  async loginPopup() {
    await this.authService.loginPopup();
   }
   async logout(popup?: boolean) {
    await this.authService.logout(popup);
  } 
  ngOnInit() {
    this.isAuthenticated = true; //this.authorizeService.isAuthenticated();
    this.initOnNavigationStart();
    this.populateNavigationData();

    /* this.isAuthenticated
      .pipe(
        concatMap((authenticated) => {
          return EMPTY;
        })
      )
      .subscribe(); */
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  private initOnNavigationStart(): void {
    this.routerSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.isExpanded = false;
      }
    });
  }

  private async populateNavigationData(): Promise<void> {
    let baseNavigationData: NavigationData[] = [
      // {
      //   link: '/',
      //   text: $localize`Home`,
      // },
    
      {
        link: '/customermanagement/cm-index',
        text: $localize`Servicios`,
        exactActiveRouterLink:false
      },
      {
        link: '/customermanagement/cm-financial',
        text: $localize`Datos Financieros`,
        exactActiveRouterLink:false
      },
      {
        link: '/customermanagement/cm-technicals',
        text: $localize`Técnicos`,
        exactActiveRouterLink:false
      },
      {
        link: '/customermanagement/cm-partes',
        text: $localize`Partes`,
        exactActiveRouterLink:false
      },
      
      // {
      //   link: '/customer-product-counter',
      //   text: $localize`CPC`,
      //   permissions: [GlobalPermissions.SuperAdmin],
      // },
      // {
      //   link: '/blocks-examples',
      //   text: $localize`Blocks examples`,
      //   permissions: [GlobalPermissions.SuperAdmin],
      // },
    ];

    this.authorizeService
      .getUser()
      .pipe(debounceTime(50), distinctUntilChanged())
      .subscribe(async () => {
        this.navigationData = [];
        for (let index = 0; index < baseNavigationData.length; index++) {
          const hasPermissions = await firstValueFrom(
            this.authorizeService.hasSomePermissions(
              baseNavigationData[index].permissions ?? []
            )
          );
          if (hasPermissions === true) {
            this.navigationData.push(baseNavigationData[index]);
          }
        }
      });
  }
}
