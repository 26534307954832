import { SwUpdate } from '@angular/service-worker';
import { ApplicationRef, Injectable, OnDestroy } from '@angular/core';
import { MessageSeverity, NotificationService } from '..';
import { concat, interval, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VersionUpdateService implements OnDestroy {
  private updatesAvailableSubscription: Subscription | undefined;
  private updatesUnrecoverableSubscription: Subscription | undefined;
  private updatesCheckForUpdateSubscription: Subscription | undefined;

  private updates: SwUpdate;
  private notification: NotificationService;
  private app: ApplicationRef;

  constructor(
    app: ApplicationRef,
    updates: SwUpdate,
    notification: NotificationService
  ) {
    this.app = app;
    this.updates = updates;
    this.notification = notification;

    if (updates.isEnabled) {
      this.checkAvailableUpdate();
      this.checkUnrecoverableUpdate();
      this.scheduleCheckForUpdates();
    }
  }

  private checkAvailableUpdate() {
    this.updatesAvailableSubscription = this.updates.available.subscribe(
      (event) => {
        this.notification.confirm({
          message: $localize`A new version of the app is available. Do you want to install it now?`,
          header: $localize`New version available`,
          acceptLabel: $localize`Install`,
          rejectLabel: $localize`Later`,
          closable: false,
          accept: () => {
            this.updates.activateUpdate().then(() => window.location.reload());
          },
          reject: (type: any) => {},
        });
      }
    );
  }

  private checkUnrecoverableUpdate() {
    this.updatesUnrecoverableSubscription =
      this.updates.unrecoverable.subscribe((event) => {
        this.notification.openAlert({
          severity: MessageSeverity.Warning,
          header: $localize`Attention!`,
          content: $localize`We have detected an error in the version of the installed application. Please refresh the page to fix it.`,
        });
      });
  }

  private scheduleCheckForUpdates() {
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = this.app.isStable.pipe(
      first((isStable) => isStable === true)
    );
    const checkInterval$ = interval(environment.pwaPoolingUpdatesInterval);
    const everyIntervalOnceAppIsStable$ = concat(appIsStable$, checkInterval$);
    this.updatesCheckForUpdateSubscription =
      everyIntervalOnceAppIsStable$.subscribe(() =>
        this.updates.checkForUpdate()
      );
  }

  ngOnDestroy(): void {
    this.updatesAvailableSubscription?.unsubscribe();
    this.updatesUnrecoverableSubscription?.unsubscribe();
    this.updatesCheckForUpdateSubscription?.unsubscribe();
  }
}
