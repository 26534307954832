import { RoleDto } from "./RoleDto.model";

export class UserRoleDto implements IUserRoleDto {
  role?: RoleDto;

  constructor(data?: IUserRoleDto) {
      if (data) {
          for (var property in data) {
              if (data.hasOwnProperty(property))
                  (<any>this)[property] = (<any>data)[property];
          }
      }
  }

  init(_data?: any) {
      if (_data) {
          this.role = _data["role"] ? RoleDto.fromJS(_data["role"]) : <any>undefined;
      }
  }

  static fromJS(data: any): UserRoleDto {
      data = typeof data === 'object' ? data : {};
      let result = new UserRoleDto();
      result.init(data);
      return result;
  }

  toJSON(data?: any) {
      data = typeof data === 'object' ? data : {};
      data["role"] = this.role ? this.role.toJSON() : <any>undefined;
      return data;
  }
}

export interface IUserRoleDto {
  role?: RoleDto;
}
