import { Injectable } from '@angular/core';
import { Confirmation, ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { ToastMessage, AlertMessage, MessageSeverity, ConfirmationMessage } from '../models';

import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class PrimengNotificationService extends NotificationService {
  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {
    super();
  }

  confirm(message: ConfirmationMessage): void {
    this.confirmationMessageSubject.next(message);
    this.confirmationService.confirm({...message, closeOnEscape: message.closable ?? false, rejectButtonStyleClass: 'p-button-text'});
  }

  openAlert(message: AlertMessage): void {
    this.alertMessageSubject.next(message);
  }

  openToast(message: ToastMessage): void {
    this.toastMessageSubject.next(message);
    this.messageService.add(message);
  }
}
