import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingCounters: number = 0;
  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    false as boolean
  );
  public readonly loading: Observable<boolean> =
    this.loadingSubject.asObservable();

  constructor() {}

  setLoading(loading: boolean): void {
    this.loadingCounters += (loading ? 1 : -1);
    this.loadingSubject.next(this.loadingCounters > 0);
  }
}
