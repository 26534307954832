export class GoalsStatus {
  private static statuses: GoalsStatus[] = [];

  static toList(): GoalsStatus[] {
    return this.statuses;
  }

  static readonly Draft = new GoalsStatus(1, 'D', 'DRAFT');
  static readonly InProgress = new GoalsStatus(2, 'IP', 'IN PROGRESS');
  static readonly Finished = new GoalsStatus(3, 'F', 'FINISHED');
  static readonly Complete = new GoalsStatus(4, 'C', 'COMPLETE');
  static readonly Unstarted = new GoalsStatus(5, 'U', 'UNSTARTED');
  static readonly WaitingForApproval = new GoalsStatus(6, 'WA', 'WAITING FOR APPROVAL');
  static readonly Rejected = new GoalsStatus(7, 'RJ', 'REJECTED');
  static readonly Approved = new GoalsStatus(8, 'AP', 'APPROVED');
  static readonly UnderEvaluation = new GoalsStatus(9, 'UE', 'UNDER EVALUATION');
  static readonly UnderDefinition = new GoalsStatus(10, 'UD', 'UNDER DEFINITION');

  constructor(
    public readonly id: number,
    public readonly code: string,
    public readonly name: string
  ) {
    GoalsStatus.statuses.push(this);
  }
}
