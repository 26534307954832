export class MonthNYearIn {
  public customerCode: number;
  public month: number;
  public year: number;
  public page: number;
  public pageSize: number;
  constructor(
    customerCode: number,
    month: number,
    year: number,
    page: number,
    pageSize: number
  ) {
    this.customerCode = customerCode;
    this.month = month;
    this.year = year;
    this.page = page;
    this.pageSize = pageSize;
  }
}
