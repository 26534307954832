import { PrimengLocale } from 'app/core/models/primeng-locale.model';

export const primengLocale: PrimengLocale = {
  locale: 'pt',
  translation: {
    startsWith: 'Começa com',
    contains: 'Contém',
    notContains: 'Não contém',
    endsWith: 'termina com',
    equals: 'Igual a',
    notEquals: 'Não é igual',
    noFilter: 'Sem filtro',
    lt: 'Menos do que',
    lte: 'Menor ou igual a',
    gt: 'Maior do que',
    gte: 'Maior ou igual a',
    is: 'É',
    isNot: 'Não é',
    before: 'Antes',
    after: 'Depois',
    dateIs: 'A data é',
    dateIsNot: 'A data não é',
    dateBefore: 'A data é antes',
    dateAfter: 'A data é depois',
    clear: 'Limpar',
    apply: 'Aplicar',
    matchAll: 'Todos concordam',
    matchAny: 'Combinar com qualquer pessoa',
    addRule: 'Adicionar regra',
    removeRule: 'Remover regra',
    accept: 'Sim',
    reject: 'Não',
    choose: 'Escolher',
    upload: 'Upload',
    cancel: 'Cancelar',
    dayNames: [
      'Domingo',
      'Segunda-feira',
      'Terça-feira',
      'Quarta-feira',
      'Quinta-feira',
      'Sexta-feira',
      'Sábado',
    ],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qa', 'Qi', 'Se', 'Sa'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    monthNamesShort: [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ],
    dateFormat: 'dd/mm/yy',
    firstDayOfWeek: 0,
    today: 'Hoje',
    weekHeader: 'Sm',
    weak: 'Fraco',
    medium: 'Médio',
    strong: 'Forte',
    passwordPrompt: 'Digite uma senha',
    emptyMessage: 'Nenhum resultado encontrado',
    emptyFilterMessage: 'Nenhum resultado encontrado',
  },
};
