import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

export type DateSelectorType = 'date' | 'month' | 'year';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateSelectorComponent implements OnInit {
  dateValue: Date = new Date();
  viewValue: DateSelectorType = 'date';
  dateFormat: string = '';

  @Input()
  get view(): DateSelectorType {
    return this.viewValue;
  }
  set view(value) {
    this.viewValue = value;

    if (value === 'month') {
      this.dateFormat = 'mm/yy';
    }
    else if(value === 'year') {
      this.dateFormat = 'yy';
    }
  }

  @Input()
  minDate: Date = new Date();

  @Input()
  maxDate: Date = new Date();

  @Input()
  get date(): Date {
    return this.dateValue;
  }
  set date(value) {
    this.dateValue = value;
  }

  @Output() dateChange = new EventEmitter<Date>();

  constructor() {}

  onSelect(date: Date) {
    this.dateChange.emit(this.dateValue);
	}

  ngOnInit(): void {}
}
