<p-dialog
  [header]="getHeader()"
  [(visible)]="visible"
  [modal]="true"
  [closeOnEscape]="true"
  [closable]="true"
  [styleClass]="getStyleClass()"
>
  <ng-container *ngIf="isArray(message?.content)">
    <p [innerHTML]="item" *ngFor="let item of asArray(message?.content)" [ngClass]="{'mb-3': asArray(message?.content).length > 1}" class="m-0">
    </p>
  </ng-container>
  <ng-container *ngIf="!isArray(message?.content)">
    <p [innerHTML]="message?.content" class="m-0">
    </p>
  </ng-container>
</p-dialog>
