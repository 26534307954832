
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, combineLatest, concat, filter, first, from, map, of, switchMap, take, tap } from 'rxjs';
import { AuthClient } from 'app/web-api-client';
import { Permission } from './permission.model';


export type IAuthenticationResult =
  | SuccessAuthenticationResult
  | FailureAuthenticationResult
  | RedirectAuthenticationResult;

export interface SuccessAuthenticationResult {
  status: AuthenticationResultStatus.Success;
  state: any;
}

export interface FailureAuthenticationResult {
  status: AuthenticationResultStatus.Fail;
  message: string;
}

export interface RedirectAuthenticationResult {
  status: AuthenticationResultStatus.Redirect;
}

export enum AuthenticationResultStatus {
  Success,
  Redirect,
  Fail,
}

export interface IUser {
  UserName: string;
  UserId: string;
  Token: string;
  [key: string]: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthorizeService {
  private popUpDisabled = true;
  private jwtHelper = new JwtHelperService();

  private userSubject: BehaviorSubject<IUser | null> =
    new BehaviorSubject<IUser | null>(null);

  private sessionExpiredSubject = new BehaviorSubject<boolean>(false);

  public sessionExpired = this.sessionExpiredSubject.asObservable();

  constructor(
      private authClient: AuthClient) {}

  getToken(): string | null {
    return sessionStorage.getItem('access_token');
  }

  isAuthenticated(): Observable<boolean> {
    const token = this.getToken();
    let isIn = !!token && !this.jwtHelper.isTokenExpired(token);
    // Verifica si el token está presente y no ha expirado
    return of(isIn);
  }

  getAccessToken(): Observable<string | null> {
    let token = of(sessionStorage.getItem('access_token')!);
    return token;
  }

  async signOut(state: any): Promise<IAuthenticationResult> {

    try {
      this.authClient
      .signOut()
      .pipe(first())
      .subscribe((result) => {
        // Limpiar cualquier información de autenticación almacenada localmente
        this.clearAuthentication();
      });

      return this.success(state)
    } catch (error: any) {
      return this.error(error);
    }
  }

  hasSomePermissions(permissions: Permission[]): Observable<boolean> {
    if (permissions.length === 0) {
      return of(true);
    }

    const permissionsList = this.mapPermissions(permissions);

    return combineLatest(permissionsList).pipe(
      // Use the every() array method to check if at least one permissions values are true
      map((p) => p.some((b) => b === true))
    );
  }

  hasPermission(permission: string): Observable<boolean> {
    const token = this.getToken();

    if (token) {
      const decodedToken = this.jwtHelper.decodeToken(token);

      if (decodedToken) {
        const permissions: string[] = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] || [];

        return of(permissions.includes(permission));
      }
    }

    return of(false);
  }

  getUser(): Observable<IUser | null> {
    return concat(
      this.userSubject.pipe(
        take(1),
        filter((user) => !!user)
      ),
      this.getUserFromStorage().pipe(
        filter((user) => !!user),
        tap((user) => {
          this.setAuth(user);
        })
      ),
      this.userSubject.asObservable()
    );
  }

  setAuth(user: IUser | null) {
    this.userSubject.next(user);
    // if (user) {
    //   this.setSessionExpired(false);
    // }
  }

  getUserFromStorage(): Observable<IUser | null> {
    const userJson = sessionStorage.getItem('user');

    const user: IUser = JSON.parse(userJson!);
    return of(user);
  }

  private success(state: any): IAuthenticationResult {
    return { status: AuthenticationResultStatus.Success, state };
  }

  private error(message: string): IAuthenticationResult {
    return { status: AuthenticationResultStatus.Fail, message };
  }

  private clearAuthentication(): void {
    // Limpiar cualquier información de autenticación almacenada localmente
    // Por ejemplo, puedes borrar el token del almacenamiento local
    sessionStorage.removeItem('access_token');
  }

  private mapPermissions(permissions: Permission[]): Observable<boolean>[] {
    const permissionsList: Observable<boolean>[] = permissions.map(
      (permission) => this.hasPermission(permission.name!)
    );

    return permissionsList;
  }
}
