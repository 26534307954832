import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
//import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
//import { AuthorizeInterceptor } from 'src/api-authorization/authorize.interceptor';
import { MsalInterceptor, MSAL_INSTANCE, MsalInterceptorConfiguration, MsalGuardConfiguration, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalService, MsalGuard, MsalBroadcastService, MsalModule } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel, BrowserUtils } from '@azure/msal-browser';
import { TreeModule } from 'primeng/tree';
import { CoreModule } from './core';
import { SharedModule } from './shared';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HomeComponent } from './home/home.component';
import { TokenComponent } from './token/token.component';

import { environment } from 'environments/environment';
import { API_BASE_URL } from './web-api-client';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { AuthorizeInterceptor } from 'api-authorization';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiAuthorizationModule } from 'api-authorization/api-authorization.module';
import { AuthenticationService } from './authentication/authentication.service';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { InputNumberModule } from 'primeng/inputnumber';

registerLocaleData(localeEs);
export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message+ " ----- "+logLevel.toString());
  }
export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
      auth: {
        clientId: environment.msalConfig.auth.clientId,
        authority: environment.msalConfig.auth.authority,
        redirectUri: '/',
        postLogoutRedirectUri: '/'
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage
      },
      system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false
        }
      }
    });
  }
  
  export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(environment.iotApiBaseUrl, environment.apiConfig.scopes);
  
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap
    };
  }
  
  export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return { 
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: [...environment.apiConfig.scopes]
      },
      loginFailedRoute: '/login-failed'
    };
  }

@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        HomeComponent,
        TokenComponent,
    ],
    providers: [
      { provide: LOCALE_ID, useValue: 'es' } ,
        /*{
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory // Configuración para MSALGuard
          },
          {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory // Configuración para MSALGuard
          },
          {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory // Configuración para MSALInterceptor
          },
          MsalService,*/
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory // Configuración para MSALInterceptor
          },
        {
            provide: API_BASE_URL,
            useValue: environment.iotApiBaseUrl,
        },
        
         MsalService,
          MsalGuard,
          MsalBroadcastService,
          AuthenticationService
          // Otros proveedores
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        CoreModule,
        SharedModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        FullCalendarModule,
        TreeModule,
        InputNumberModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
       // ApiAuthorizationModule,
        MsalModule.forRoot(
            new PublicClientApplication({
                auth: {
                    clientId: environment.msalConfig.auth.clientId,
                    authority: environment.msalConfig.auth.authority,
                    redirectUri: '/',
                    postLogoutRedirectUri: '/'
                  },
                  cache: {
                    cacheLocation: BrowserCacheLocation.LocalStorage
                  },
                  system: {
                    allowNativeBroker: false, // Disables WAM Broker
                    loggerOptions: {
                      loggerCallback,
                      logLevel: LogLevel.Info,
                      piiLoggingEnabled: false
                    }
                  }
            }),
            {
                interactionType: InteractionType.Redirect, // MSAL Guard Configuration
                authRequest: {
                  scopes: ["user.read"],
                },
              },
            MSALInterceptorConfigFactory()
          ),
    ]
})
export class AppModule {}

//TODO: Add https://purgecss.com/ to reduce PrimeNG and PrimeFlex bundles.
