/**
 * Make this a module.
 */
export {};

/**
 * Access the global types
 */
declare global {
  /**
   * Extend Array<T> interface with 'elementAt', 'firstOrDefault', 'lastOrDefault', 'singleOrDefault' util.
   */
  interface Array<T> {
    elementAt(pos: number | undefined): T | undefined;
    firstOrDefault(def?: T | null): T | null;
    lastOrDefault(def?: T | null): T | null;
    singleOrDefault(
      predicate?: (element: T) => boolean,
      def?: T | null
    ): T | null;
  }
}

/**
 * Implement 'elementAt' util.
 */
 if (!Array.prototype.elementAt) {
  Array.prototype.elementAt = function <T>(
    pos: number | undefined
  ): T | undefined {
    if (
      pos === undefined ||
      !this?.length ||
      this.length < 0 ||
      pos >= this.length ||
      pos < 0
    ) {
      return undefined;
    }

    return this[pos];
  };
}

/**
 * Implement 'firstOrDefault' util.
 */
if (!Array.prototype.firstOrDefault) {
  Array.prototype.firstOrDefault = function <T>(def?: T | null): T | null {
    if (this.length === 0) {
      return def ?? null;
    }
    return this[0];
  };
}

/**
 * Implement 'lastOrDefault' util.
 */
 if (!Array.prototype.lastOrDefault) {
  Array.prototype.lastOrDefault = function <T>(def?: T | null): T | null {
    if (this.length === 0) {
      return def ?? null;
    }
    return this[this.length - 1];
  };
}

/**
 * Implement 'singleOrDefault' util.
 */
 if (!Array.prototype.singleOrDefault) {
  Array.prototype.singleOrDefault = function <T>(
    predicate?: (element: T) => boolean,
    def?: T | null
  ): T | null {
    if (!predicate) {
      if (this.length === 0) {
        return def ?? null;
      }
      return this[0];
    }

    for (let i = 0; i < this.length; ++i) {
      if (predicate(this[i])) {
        return this[i];
      }
    }

    return def ?? null;
  };
}
