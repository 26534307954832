// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//const iotApiBaseUrl = 'https://localhost:7047';
const iotApiBaseUrl = 'https://apilumar.azurewebsites.net';

const packageJson = require('../../package.json');

export const environment = {
  version: `${packageJson.version}-development`,
  production: false,
  iotApiBaseUrl: iotApiBaseUrl,
  pwaPoolingUpdatesInterval: 600000,
  msalConfig: {
    auth: {
        clientId: '20478884-d6e6-44ef-8067-ff30ff2e2f39',
        authority: 'https://login.microsoftonline.com/d297f8af-73a0-48c6-814b-1950d569aae9'
    }
},
apiConfig: {
    scopes: ['api://20478884-d6e6-44ef-8067-ff30ff2e2f39/access_as_user'],
    uri: 'https://graph.microsoft.com/v1.0/me'
}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
