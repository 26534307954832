import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoadingService } from 'app/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  animations: [
    trigger('loadingAnimation', [
      state(
        'close',
        style({
          display: 'none',
          opacity: 1,
        })
      ),
      state(
        'open',
        style({
          display: 'block',
          opacity: 1,
        })
      ),
      transition('close <=> open', animate(200)),
    ]),
  ],
})
export class LoadingComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;

  private loadingServiceSubscription: Subscription | undefined;

  constructor(public loadingService: LoadingService) {
    this.loadingServiceSubscription = loadingService.loading.subscribe(
      (isLoading) => (this.isLoading = isLoading)
    );
  }

  getLoadingAnimationValue(): string {
    return this.isLoading ? 'open' : 'close';
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.loadingServiceSubscription?.unsubscribe();
  }
}
