import { Component, OnInit } from '@angular/core';
import { ConfirmationMessageEvent, MessageSeverity, NotificationService } from 'app/core';

@Component({
  selector: 'app-blocks-examples',
  templateUrl: './blocks-examples.component.html',
  styleUrls: ['./blocks-examples.component.css']
})
export class BlocksExamplesComponent implements OnInit {
  modalDialogVisible: boolean = false;

  constructor(private notificationService: NotificationService) { }

  testConfirm(): void {
    this.notificationService.confirm({
      message: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Are you sure that you want to proceed?',
      header: 'This is the confirmation dialog header!',
      acceptLabel: 'Alternative accept label',
      closable: false,
      accept: () => {
        this.notificationService.openToast({
          severity: MessageSeverity.Info,
          summary: 'Confirmed',
          detail: 'You have accepted',
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmationMessageEvent.REJECT:
            this.notificationService.openToast({
              severity: MessageSeverity.Error,
              summary: 'Rejected',
              detail: 'You have rejected',
            });
            break;
          case ConfirmationMessageEvent.CANCEL:
            this.notificationService.openToast({
              severity: MessageSeverity.Warning,
              summary: 'Cancelled',
              detail: 'You have cancelled',
            });
            break;
        }
      },
    });
  }

  testInfoAlert(): void {
    this.notificationService.openAlert({
      severity: MessageSeverity.Info,
      header: `This is the info alert header!`,
      content: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tenetur enim dolorem sit delectus tempora distinctio nostrum, eveniet eos optio dolore accusamus! Repellendus sint voluptatibus odit? Voluptatem, voluptas natus. Necessitatibus, laudantium.`
    })
  }

  toggleModalDialog(): void {
    this.modalDialogVisible = !this.modalDialogVisible;
  }

  testWarningAlert(): void {
    this.notificationService.openAlert({
      severity: MessageSeverity.Warning,
      header: `This is the warning alert header!`,
      content: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tenetur enim dolorem sit delectus tempora distinctio nostrum, eveniet eos optio dolore accusamus! Repellendus sint voluptatibus odit? Voluptatem, voluptas natus. Necessitatibus, laudantium.`
    })
  }

  ngOnInit(): void {
  }
}
